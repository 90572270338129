<template>
  <div class="flex flex-col gap-4 mb-4">
    <div class="flex flex-wrap gap-4">
      <div class="flex items-center gap-2 flex-grow">
        <label for="start" class="text-gray-700">Start Date:</label>
        <input
          id="start"
          name="start"
          type="date"
          class="input--text--small placeholder:text-gray-400 date-input flex-grow"
          placeholder="Select date start"
          @input="getDateRangeOption1($event.target.value)"
        />
      </div>
      <div class="flex items-center gap-2 flex-grow">
        <label for="end" class="text-gray-700">End Date:</label>
        <input
          id="end"
          name="end"
          type="date"
          class="input--text--small placeholder:text-gray-400 date-input flex-grow"
          placeholder="Select date end"
          @input="getDateRangeOption2($event.target.value)"
        />
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <button
        @click="exportData"
        class="btn--table--export flex-1 max-w-full mb-2 btn-campaign-export"
      >
        Export Day's All Campaign Data
      </button>
      <button
        @click="exportData"
        class="btn--table--export flex-1 max-w-full mb-2 btn-campaign-export"
      >
        Export All Data by Date Range
      </button>
    </div>
  </div>
  <div>
    <!-- Canvassing Org Campaign List (mobile) -->
    <div class="md:hidden">
      <ul
        v-if="campaigns?.length"
        role="list"
        class="mt-2 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden shadow"
      >
        <li v-for="(campaign, key) in campaigns" :key="key">
          <div class="px-4 pt-4 truncate">
            <div class="flex flex-row justify-between">
              <strong class="block truncate">{{
                campaign.location ? campaign.name + ' ' + campaign.location : campaign.name
              }}</strong>
              <RouterLink
                :to="{ name: 'CampaignData', params: { id: campaign.id } }"
                class="btn--table"
              >
                View
              </RouterLink>
            </div>
            <span class="truncate">{{ campaign.npo.name }}</span>
          </div>
          <div class="flex flex-col bg-white p-2 text-sm">
            <div class="flex flex-row">
              <CheckIcon v-if="campaign.collectingDonations" class="h-5 text-primary" />
              <XMarkIcon v-else class="h-5 text-secondary" />
              <p class="ml-1">Collecting Donations</p>
            </div>
            <div class="flex flex-row">
              <CheckIcon v-if="campaign.collectingLeads" class="h-5 text-primary" />
              <XMarkIcon v-else class="h-5 text-secondary" />
              <p class="ml-1">Collecting Leads</p>
            </div>
            <div class="flex flex-row">
              <CheckIcon v-if="campaign.active" class="h-5 text-primary" />
              <XMarkIcon v-else class="h-5 text-secondary" />
              <p class="ml-1">{{ campaign.active ? 'Active' : 'Inactive' }}</p>
            </div>
          </div>
        </li>
      </ul>
      <p v-else class="mt-2 p-4 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden">
        No results found.
      </p>
    </div>
    <!-- Canvassing Org Campaign List (desktop) -->
    <div class="hidden md:block">
      <div class="mt-2 flex flex-col">
        <div class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                <th
                  scope="col"
                  class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  NPO
                </th>
                <th
                  scope="col"
                  class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Collecting Donations
                </th>
                <th
                  scope="col"
                  class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Collecting Leads
                </th>
                <th
                  scope="col"
                  class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Active
                </th>
                <th
                  scope="col"
                  class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Actions
                </th>
              </tr>
            </thead>

            <tbody class="divide-y divide-gray-200 bg-white">
              <template v-if="campaigns?.length">
                <tr v-for="(campaign, key) in campaigns" :key="key">
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    {{
                      campaign.location ? campaign.name + ' ' + campaign.location : campaign.name
                    }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    {{ campaign.npo.name }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    <CheckIcon v-if="campaign.collectingDonations" class="h-8 w-8 text-primary" />
                    <XMarkIcon v-else class="h-8 w-8 text-secondary" />
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    <CheckIcon v-if="campaign.collectingLeads" class="h-8 w-8 text-primary" />
                    <XMarkIcon v-else class="h-8 w-8 text-secondary" />
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    <CheckIcon v-if="campaign.active" class="h-8 w-8 text-primary" />
                    <XMarkIcon v-else class="h-8 w-8 text-secondary" />
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    <RouterLink
                      :to="{ name: 'CampaignData', params: { id: campaign.id } }"
                      class="btn--table"
                    >
                      View
                    </RouterLink>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="6" class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                  No results found.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { reactive, toRefs } from 'vue'
import { WorkedHoursApi } from '../services/workingHours'
import { makeCSV } from '../services/workingHours/daily_working_stats_csv'
export default {
  name: 'CanvassingOrgCampaigns',
  components: {
    CheckIcon,
    XMarkIcon,
  },
  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { campaigns } = toRefs(props)

    const getCanvassingOrg = (campaign) => {
      if (campaign && campaign.length > 0) {
        return campaign[0].canvassingOrg
      }
      return undefined
    }

    const formatDateQueryParam = (date) => {
      return new Date(date).toISOString().split('T')[0]
    }
    const selectedDate = reactive({
      date1: '',
      date2: '',
    })

    const getDateRangeOption1 = (date) => {
      if (date) {
        date = date.replace(/-/g, '/')
        selectedDate.date1 = formatDateQueryParam(date)
      } else {
        selectedDate.date1 = ''
      }
    }

    const getDateRangeOption2 = (date) => {
      if (date) {
        date = date.replace(/-/g, '/')
        selectedDate.date2 = formatDateQueryParam(date)
      } else {
        selectedDate.date2 = ''
      }
    }

    const exportData = async () => {
      if (selectedDate.date1 || selectedDate.date2) {
        const canvassingOrg = getCanvassingOrg(campaigns.value)

        if (!selectedDate.date1 || !selectedDate.date2) {
          let date = ''
          if (selectedDate.date1) {
            date = selectedDate.date1
          } else {
            date = selectedDate.date2
          }
          try {
            const response = await WorkedHoursApi.csc.customRetrieveAll({
              canvassing_org: canvassingOrg,
              date: date,
            })
            makeCSV(response)
          } catch (error) {
            console.error('Error exporting range data:', error)
          }
        } else {
          let startDate = new Date(selectedDate.date1)
          let endDate = new Date(selectedDate.date2)
          if (startDate > endDate) {
            console.warn('Start date is later than end date. Swapping dates.')[
              (startDate, endDate)
            ] = [endDate, startDate]
          }
          const formattedStartDate = startDate.toISOString().split('T')[0]
          const formattedEndDate = endDate.toISOString().split('T')[0]

          try {
            const response = await WorkedHoursApi.csc.rangeRetrieveAll({
              canvassing_org: canvassingOrg,
              start_date: formattedStartDate,
              end_date: formattedEndDate,
            })
            makeCSV(response)
          } catch (error) {
            console.error('Error exporting range data:', error)
          }
        }
      }
    }
    return {
      getDateRangeOption1,
      getDateRangeOption2,
      exportData,
    }
  },
}
</script>
