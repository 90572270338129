<template>
  <span
    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium self-start"
    :class="status === 'active' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-600'"
    >{{ text }}</span
  >
</template>

<script>
export default {
  props: ['status', 'text'],
}
</script>
