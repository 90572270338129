<template>
  <DashboardContainer>
    <Spinner v-if="loading" class="mt-24" />
    <template v-else>
      <template v-if="isCanvassingOrgAdmin">
        <CanvassingOrgCampaignTable :campaigns="campaigns.list" />
      </template>
      <template v-else>
        <!-- Campaign list (mobile) -->
        <div class="md:hidden">
          <ul
            v-if="campaigns.list?.length"
            role="list"
            class="mt-2 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden shadow"
          >
            <li v-for="(campaign, key) in campaigns.list" :key="key">
              <div class="px-4 pt-4 truncate">
                <strong class="block truncate">{{
                  campaign.location ? campaign.name + ' ' + campaign.location : campaign.name
                }}</strong>
                <span class="truncate">{{ campaign.npo.name }}</span>
              </div>
              <div class="flex bg-white p-4 text-sm space-x-4">
                <RouterLink
                  :to="{ name: 'CampaignDonate', params: { id: campaign.id } }"
                  :class="(!donorFormIsActive(campaign) ? 'disabled' : '') + ' btn--tertiary w-1/2'"
                >
                  Donor Form
                  <ArrowRightIcon class="h-4 w-4 inline" aria-hidden="true" />
                </RouterLink>
                <RouterLink
                  :to="{ name: 'CampaignLead', params: { id: campaign.id } }"
                  :class="
                    (!leadFormIsActive(campaign) ? 'disabled' : '') + ' ml-auto btn--tertiary w-1/2'
                  "
                >
                  Lead Form
                  <ArrowRightIcon class="h-4 w-4 inline" aria-hidden="true" />
                </RouterLink>
              </div>
            </li>
          </ul>
          <p v-else class="mt-2 p-4 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden">
            No results found.
          </p>
        </div>

        <!-- Campaign list (desktop) -->
        <div class="hidden md:block">
          <div class="mt-2 flex flex-col">
            <div
              class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                    <th
                      scope="col"
                      class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      NPO
                    </th>
                    <th class="bg-gray-50">
                      <span class="invisible">Donor Form Link</span>
                    </th>
                    <th class="bg-gray-50">
                      <span class="invisible">Lead Form Link</span>
                    </th>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200 bg-white">
                  <template v-if="campaigns.list?.length">
                    <tr v-for="(campaign, key) in campaigns.list" :key="key">
                      <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                        {{
                          campaign.location
                            ? campaign.name + ' ' + campaign.location
                            : campaign.name
                        }}
                      </td>
                      <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                        {{ campaign.npo.name }}
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 text-sm font-semibold">
                        <RouterLink
                          :to="{ name: 'CampaignDonate', params: { id: campaign.id } }"
                          :class="!donorFormIsActive(campaign) ? 'disabled' : ''"
                          target="_blank"
                        >
                          Donor Form
                          <ArrowRightIcon class="h-4 w-4 inline" aria-hidden="true" />
                        </RouterLink>
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 text-sm font-semibold">
                        <RouterLink
                          :to="{ name: 'CampaignLead', params: { id: campaign.id } }"
                          :class="!leadFormIsActive(campaign) ? 'disabled' : ''"
                          target="_blank"
                        >
                          Lead Form <ArrowRightIcon class="h-4 w-4 inline" aria-hidden="true" />
                        </RouterLink>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="4" class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      No results found.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </template>
  </DashboardContainer>
</template>

<script>
import { onBeforeMount, ref, triggerRef } from 'vue'
import { useStore } from 'vuex'
import DashboardContainer from '@/components/DashboardContainer.vue'
import CanvassingOrgCampaignTable from '@/components/CanvassingOrgCampaignTable.vue'
import Pill from '@/components/Pill.vue'
import Spinner from '@/components/Spinner'
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { campaignFunctions } from '@/services/campaign/'

export default {
  name: 'campaigns',
  components: {
    DashboardContainer,
    ChevronRightIcon,
    ArrowRightIcon,
    Pill,
    Spinner,
    CanvassingOrgCampaignTable,
  },
  setup() {
    const store = useStore()
    const userToken = store.getters.user.token
    const isCanvassingOrgAdmin = store.getters.isCanvassingOrgAdmin
    const { campaignCollection } = campaignFunctions()
    const campaigns = ref(campaignCollection)
    const loading = ref(false)

    const donorFormIsActive = (campaign) => {
      return campaign.active && campaign.latestPaymentProvider && campaign.collectingDonations
    }

    const leadFormIsActive = (campaign) => {
      return campaign.active && campaign.collectingLeads
    }

    onBeforeMount(async () => {
      loading.value = true
      await campaigns.value.refresh().catch((error) => {
        alert(error)
      })
      triggerRef(campaigns)
      loading.value = false
    })

    return {
      campaigns,
      loading,
      userToken,
      isCanvassingOrgAdmin,
      donorFormIsActive,
      leadFormIsActive,
    }
  },
}
</script>
